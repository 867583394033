<template>
  <div class="type-project-update-setting">
    <div class="header pb-3" v-if="selectedMenu === 'champ-personnalise'">
      <div class="style-title-setting-global">
        <span
          class="bold-700 mr-2 title-hover cursor-pointer"
          @click="handelCancel($event)"
        >
          {{ $i18n.locale == 'fr' ? 'Types des projets' : 'Project types' }}
          <v-icon class="color-crm">mdi-chevron-right</v-icon></span
        >
        <span class="color-crm font-sz-18 bold-500 font-text text-capitalize">{{
          typeName ? typeName : ''
        }}</span>
      </div>
    </div>
    <v-row class="header" v-if="selectedMenu === 'sous-type'">
      <v-col cols="5" class="flex">
        <div class="style-title-setting-global text-white-space">
          <span
            class="bold-700 mr-2 title-hover cursor-pointer"
            @click="handelCancel($event)"
          >
            {{
              $i18n.locale == 'fr'
                ? 'Sous types de projet'
                : 'Sub project types'
            }}
            <v-icon class="color-crm">mdi-chevron-right</v-icon></span
          >
          <span class="color-crm font-sz-18 bold-500 font-text text-capitalize">
            {{
              selectedProjectType && selectedProjectType.name
                ? selectedProjectType.name
                : selectedProjectType
            }}</span
          >
        </div>
      </v-col>
      <v-col
        v-if="
          (getProjectsTypesLoading && !initLoading) || getProjectsTypesError
        "
      >
        <div class="message mb-0">
          <div v-if="getProjectsTypesLoading && !initLoading" class="loading">
            {{ $t('loading') }}
          </div>
          <div v-if="getProjectsTypesError" class="error-msg">
            <ul v-if="Array.isArray(getProjectsTypesError)">
              <li v-for="(e, index) in getProjectsTypesError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getProjectsTypesError }}</span>
          </div>
        </div></v-col
      >
      <v-col>
        <v-btn
          dark
          color="#5C2DD3"
          class="float-right"
          @click.stop="handleClickBtnAddSousTypes"
        >
          <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
          {{ $i18n.locale == 'fr' ? 'Ajouter sous-type' : 'Add sub-type' }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="block-menu-users mt-2 pt-3">
      <div class="list-tabs style-tabs">
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            href="#tab-0"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('champ-personnalise')"
          >
            <div class="menu-item">
              <!-- Champs personnalisés -->
              {{ $i18n.locale == 'fr' ? 'Modifier Type' : 'Edit Type' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-1"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('sous-type')"
          >
            <div class="menu-item">
              {{ $i18n.locale == 'fr' ? 'Sous Types' : 'Sub types' }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-2 pt-3" v-else>
      <div v-if="selectedMenu === 'champ-personnalise'" class="mt-2">
        <!-- class="mt-3 scroll" -->
        <v-form v-if="projectToUpdate" class="mt-3" ref="formUpdateProjectType">
          <v-row class="mt-3">
            <v-col cols="2"></v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('name')"
                dense
                :persistent-placeholder="true"
                outlined
                color="#5C2DD3"
                v-model="projectToUpdate.name"
                item-color="#5C2DD3"
                required
                validate-on-blur
                :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
              >
              </v-text-field>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :clearable="true"
                    color="#5C2DD3"
                    :placeholder="$t('search')"
                    item-color="#5C2DD3"
                    v-model="projectToUpdate.header_field"
                    :items="getAllFieldProject"
                    :loading="false"
                    :persistent-placeholder="true"
                    :label="$t('header_field')"
                    item-text="name"
                    dense
                    item-value="id"
                    :no-data-text="$t('noDataOption')"
                    outlined
                    :menu-props="{
                      bottom: true,
                      offsetY: true
                    }"
                  >
                  </v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :label="$t('theme')"
                    dense
                    v-model="projectToUpdate.theme"
                    :items="themeList"
                    outlined
                    item-text="text"
                    item-value="value"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    :persistent-placeholder="true"
                    item-color="#5C2DD3"
                    :rules="[v => !!v || $t('theme') + ' ' + $t('msgOblg')]"
                  ></v-select
                ></v-col>
              </v-row>
              <v-switch
                class="input-checkbox switch-bottom label-switch"
                :label="$t('one_step')"
                v-model="projectToUpdate.one_step"
                color="#5C2DD3"
              >
              </v-switch>
              <v-switch
                class="input-checkbox switch-bottom label-switch"
                :label="$t('one_category_projet')"
                v-model="projectToUpdate.use_project"
                color="#5C2DD3"
              >
              </v-switch>
              <div class="block-flex-align-space-between">
                <!--  Type_Projet Modal: messages -->
                <div class="message">
                  <div
                    v-if="getProjectsTypesLoading && !initLoading"
                    class="loading ml-1"
                  >
                    {{ $t('loading') }}
                  </div>
                  <div v-if="getProjectsTypesError" class="error-msg">
                    <ul v-if="Array.isArray(getProjectsTypesError)">
                      <li
                        v-for="(e, index) in getProjectsTypesError"
                        :key="index"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <span v-else>{{ getProjectsTypesError }}</span>
                  </div>
                </div>
                <!-- Update Type_Projet Modal: buttons -->
              </div>
            </v-col>
            <v-col cols="2">
              <div class="form-actions mt-0 mb-0">
                <v-btn
                  color="#5C2DD3"
                  dark
                  :disabled="initLoading"
                  @click.prevent.stop="updateTypeProjet"
                  :loading="loadingFields"
                >
                  {{ $t('btnValider') }}
                </v-btn>
              </div></v-col
            >
          </v-row>

          <!-- <div class="mb-3">Champs personnalisés :</div>
          <div
            class="list-fildes-type-projet"
            v-if="getCustomFieldProject && getCustomFieldProject.length"
          > -->
          <!-- {{ projectToUpdate.selected }} -->
          <!-- <v-switch
              class="input-checkbox switch-bottom label-switch"
              v-for="(item, index) in getCustomFieldProject"
              :key="'fildes' + index"
              v-model="projectToUpdate.selected"
              @click="changeValueSelect(item)"
              :value="item"
              color="#5C2DD3"
              :label="item.name"
            >
            </v-switch>
          </div> -->
          <!-- <div v-else class="font-text font-sz-12 color-crm text-center">
            Aucun champ personnalisés
          </div> -->
        </v-form>
        <!-- <v-divider class="mt-2"></v-divider> -->
      </div>
      <div v-if="selectedMenu === 'sous-type'">
        <div class="card-scene" ref="cardSceneTypeProject">
          <div
            v-if="
              selectedProjectType &&
                selectedProjectType.subType &&
                selectedProjectType.subType.length
            "
          >
            <Container
              :drop-placeholder="dropPlaceholderOptions"
              :lock-axis="'y'"
              @drop="onColumnDrop"
              drag-handle-selector=".column-drag-container "
            >
              <Draggable
                v-for="sousType in selectedProjectType.subType"
                :key="sousType.id"
              >
                <div class=" ">
                  <div class="card-column-header">
                    <!-- <div
                    class="column-drag-handle color-crm column-drag-container bold-700 cursor-move"
                  >
                    ☰
                  </div> -->
                    <div class="input-title-sous-type">
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            :label="$t('name')"
                            dense
                            :placeholder="
                              $i18n.locale === 'fr'
                                ? 'Modifier le nom de sous type'
                                : 'Modify sub type name'
                            "
                            :value="sousType.name"
                            @change="updateNameSousType($event, sousType)"
                            :persistent-placeholder="true"
                            outlined
                            color="#5C2DD3"
                            item-color="#5C2DD3"
                          >
                            <template #prepend>
                              <v-icon
                                class="cursor-move"
                                :class="{
                                  'column-drag-container':
                                    selectedProjectType &&
                                    selectedProjectType.subType.length > 1
                                }"
                                >mdi-cursor-move</v-icon
                              >
                            </template>
                          </v-text-field></v-col
                        >
                        <v-col cols="4">
                          <v-autocomplete
                            :clearable="true"
                            color="#5C2DD3"
                            multiple
                            :placeholder="
                              $t('searchMsg', { msg: $t('category') })
                            "
                            item-color="#5C2DD3"
                            @input="handleCategorieChange($event, sousType)"
                            :loading="getProjectsTypesLoading"
                            v-model="sousType.selectedCategorie"
                            :items="getListCategorieInTable"
                            :persistent-placeholder="true"
                            chips
                            return-object
                            :deletable-chips="true"
                            :small-chips="true"
                            :label="$t('category')"
                            item-text="name"
                            dense
                            item-value="name"
                            :no-data-text="
                              $t('noDataOptionMsg', {
                                option: $t('category')
                              })
                            "
                            outlined
                            :menu-props="{
                              bottom: true,
                              offsetY: true
                            }"
                          >
                          </v-autocomplete
                        ></v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            :clearable="true"
                            color="#5C2DD3"
                            multiple
                            :placeholder="
                              $t('searchMsg', { msg: $t('sous_categories') })
                            "
                            item-color="#5C2DD3"
                            @input="handleSousTypeChange(sousType)"
                            v-model="sousType.subcategories.data"
                            :items="sousType.listSubCategorie || []"
                            :persistent-placeholder="true"
                            chips
                            return-object
                            :deletable-chips="true"
                            :small-chips="true"
                            :label="$t('sous_categories')"
                            item-text="name"
                            dense
                            item-value="name"
                            :no-data-text="
                              $t('noDataOptionMsg', {
                                option: $t('sous_categories')
                              })
                            "
                            outlined
                            :menu-props="{
                              bottom: true,
                              offsetY: true
                            }"
                          >
                          </v-autocomplete
                        ></v-col>
                        <v-col cols="1">
                          <v-btn
                            class="font-sz-14 bold-700 btn-deleted-user float-right"
                            @click="tryDeleteSousType(sousType)"
                            :title="
                              $i18n.locale === 'fr'
                                ? 'Supprimer un sous type'
                                : 'Delete sub type'
                            "
                            color="rgba(0, 0, 0, 0.54)"
                            fab
                            small
                            icon
                          >
                            <v-icon>mdi-close-circle-outline</v-icon>
                          </v-btn></v-col
                        >
                      </v-row>
                    </div>
                  </div>
                </div>
              </Draggable>
            </Container>
          </div>
          <div v-else class="div-aucun-list">
            <div class="titre">
              {{ $i18n.locale == 'fr' ? 'Aucun sous type' : 'No Sub types' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- delete sousType -->
    <v-dialog
      v-model="deleteSousTypeModal"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Supprimer un sous-type'
                : 'Delete sub-type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteSousTypeModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <p class="mb-3">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale == 'fr' ? 'le sous-type' : 'the sub-type'
              })
            }}
            <strong>
              {{ sousTypeToDelete ? sousTypeToDelete.name : '' }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsTypesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsTypesError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteSousType"
            :loading="getProjectsTypesLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteSousTypeModal')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Sous_Type_Projet Modal -->
    <v-dialog
      v-model="addProjetsSousTypesModal"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Ajouter un sous-type' : 'Add sub-type' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addProjetsSousTypesModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="addProjetsSousTypesModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="SousTypeToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="$t('searchMsg', { msg: $t('category') })"
                  item-color="#5C2DD3"
                  @input="handleCategorieChange($event)"
                  v-model="SousTypeToAdd.categories"
                  :items="getListCategorieInTable"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('category')"
                  item-text="name"
                  dense
                  item-value="name"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('category')
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="$t('searchMsg', { msg: $t('sous_categories') })"
                  item-color="#5C2DD3"
                  v-model="SousTypeToAdd.sub_categories"
                  :items="getListSubCategorie"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('sous_categories')"
                  item-text="name"
                  dense
                  item-value="name"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('sous_categories')
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v => {
                      if (
                        SousTypeToAdd.categories &&
                        SousTypeToAdd.categories.length > 0
                      ) {
                        return (
                          (v && v.length > 0) ||
                          $t('msgObligMsg', { msg: $t('sous_categories') })
                        )
                      } else {
                        return true // Pas de validation si aucune catégorie sélectionnée
                      }
                    }
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsTypesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsTypesError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="addBlockSousType"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addProjetsSousTypesModal')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      model: 'tab-0',
      initLoading: true,
      selectedMenu: 'champ-personnalise',
      selectedProjectType: null,
      SousTypeToAdd: {
        name: null,
        sub_categories: [],
        categories: []
      },
      typeName: null,
      customizable: 1,
      upperDropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      sousTypeToDelete: null,
      error: null,
      projectToUpdate: null,
      deleteSousTypeModal: false,
      addProjetsSousTypesModal: false,
      loading: false,
      loadingFields: false
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchProjetsTypessubtypes',
      'addSubType',
      'fetchAllTablesCustomFields',
      'fetchSubCategorieForSubType',
      'fetchSubCategorieForCategorie',
      'fetchListCategorieInTable',
      'updateCategoriesCategorie',
      'addSubCategorie',
      'deleteSubType',
      'updateSubType',
      'resetErrorProjectType',
      'updateProjectType',
      'fetchCustomFieldProject',
      'fetchAllFieldProject'
    ]),
    changeValueSelect() {},
    handleClickBtnAddSousTypes() {
      this.addProjetsSousTypesModal = true
    },
    clickMenu(menu) {
      this.initLoading = true
      this.selectedMenu = menu
      this.resetErrorProjectType()
      if (menu == 'champ-personnalise') {
        const search = this.getProjectsTypes.find(
          projectType => projectType.id == this.$route.params.id
        )
        if (search) {
          this.projectToUpdate = { ...search }
        }
      }

      setTimeout(() => {
        this.initLoading = false
      }, 800)
    },
    handleCategorieChange($event, sousType) {
      this.fetchSubCategorieForCategorie({ event: $event, sousType })
    },
    //updated
    async updateTypeProject() {
      if (
        this.selectedProjectType &&
        this.typeName == this.selectedProjectType.name
      ) {
        return
      }

      const data = {}

      if (
        this.selectedProjectType &&
        this.typeName != this.selectedProjectType.name
      ) {
        data.name = this.typeName
      }
      const response = await this.updateCategoriesCategorie({
        project: this.selectedProjectType,
        data: data
      })
      if (response) {
        this.$bvToast.toast('Données envoyées avec succès', {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      }
    },
    //updated
    async addBlockSousType() {
      if (this.$refs.addProjetsSousTypesModal.validate()) {
        this.loading = true
        let count =
          this.selectedProjectType &&
          this.selectedProjectType.subType &&
          this.selectedProjectType.subType.length
            ? this.selectedProjectType.subType.length
            : 0
        const sousType = {
          name: this.SousTypeToAdd.name,
          order: count,
          sub_categories: this.SousTypeToAdd.sub_categories.map(item => {
            return item.id
          })
        }
        const response = await this.addSubType({
          type: this.selectedProjectType,
          sousType: sousType
        })
        //scroll when add new stage
        if (response) {
          this.$nextTick(() => {
            this.$refs.cardSceneTypeProject.scrollTop = this.$refs.cardSceneTypeProject.scrollHeight
            this.closeDialog('addProjetsSousTypesModal')
          })
        }
        this.loading = false
      }
    },
    //updated
    updateNameSousType(e, sousType) {
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: sousType,
        data: { name: e }
      })
    },
    //update
    tryDeleteSousType(sousType) {
      this.sousTypeToDelete = sousType
      this.deleteSousTypeModal = true
    },
    //updated
    async deleteSousType() {
      const response = await this.deleteSubType({
        type: this.selectedProjectType,
        sousType: this.sousTypeToDelete
      })
      if (response) {
        this.closeDialog('deleteSousTypeModal')
      }
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addProjetsSousTypesModal') {
        this.$refs.addProjetsSousTypesModal.resetValidation()
      }
      this.resetModal()
    },

    resetModal() {
      this.sousTypeToDelete = null
      this.SousTypeToAdd = {
        name: null,
        sub_categories: [],
        categories: []
      }
      this.resetErrorProjectType()
    },

    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.selectedProjectType.subType = this.applyDrag(
        this.selectedProjectType.subType,
        dropResult
      )
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: this.selectedProjectType.subType[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    //updated
    handelCancel() {
      this.$router.replace('/setting/projects-types').catch(() => {})
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    handleSousTypeChange(sousType) {
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: sousType,
        data: {
          sub_categories: sousType.subcategories.data.map(item => {
            return item.id
          })
        }
      })
    },
    async getSubCategorieForSubTypes() {
      for (let i = 0; i < this.selectedProjectType.subType.length; i++) {
        await this.fetchSubCategorieForSubType({
          type: this.selectedProjectType,
          sousType: this.selectedProjectType.subType[i]
        })
      }
    },
    async updateTypeProjet() {
      if (this.$refs.formUpdateProjectType.validate()) {
        this.loadingFields = true
        let body = {
          name: this.projectToUpdate.name,
          // selected: this.projectToUpdate.selected.map(item => {
          //   return item.id
          // }),
          selected: [],
          id: this.projectToUpdate.id,
          one_step: this.projectToUpdate.one_step,
          use_project: this.projectToUpdate.use_project,
          theme: this.projectToUpdate.theme,
          header_field: this.projectToUpdate.header_field
        }
        const res = await this.updateProjectType(body)
        if (res) {
          const search = this.getProjectsTypes.find(
            projectType => projectType.id == this.$route.params.id
          )
          if (search) this.projectToUpdate = search
        }
        this.fetchProjetsTypes()
        this.resetErrorProjectType()
        setTimeout(() => {
          this.loadingFields = false
        }, 800)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'getProjectsTypesError',
      'getProjectSubTypes',
      'getListCategorieInTable',
      'getListSubCategorie',
      'getCustomFieldProject',
      'getCustomFieldsMenu',
      'getAllFieldProject'
    ]),
    themeList() {
      return [
        { value: 'standard', text: this.$t('default_theme') },
        { value: 'immobilier', text: this.$t('immo_theme') }
      ]
    }
  },
  async mounted() {
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes()
    }

    const search = this.getProjectsTypes.find(
      projectType => projectType.id == this.$route.params.id
    )
    if (!search) {
      this.$router.push('/setting/projects-types')
    }
    this.selectedProjectType = search
    this.fetchAllFieldProject({ table_id: 42, type_id: this.$route.params.id })
    await this.fetchProjetsTypessubtypes(this.selectedProjectType)
    await this.fetchAllTablesCustomFields(this.customizable)
    let idTable = this.getCustomFieldsMenu.find(item => item.name === 'projets')
    await this.fetchListCategorieInTable({
      idTable,
      include: 'subcategories'
    })
    this.getSubCategorieForSubTypes()

    this.typeName = search && search.name ? search.name : search
    this.projectToUpdate = { ...search }
    if (this.projectToUpdate.fields && this.projectToUpdate.fields.length) {
      this.projectToUpdate.selected = this.projectToUpdate.fields
    }
    if (this.getCustomFieldProject.length === 0) {
      this.fetchCustomFieldProject()
    }
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.type-project-update-setting {
  .header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: start;
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  .list-fildes-type-projet {
    max-height: calc(100vh - 400px) !important;
    height: calc(100vh - 400px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-fildes-type-projet::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-fildes-type-projet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-fildes-type-projet::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .form-actions {
    float: right;
    margin-top: 10px;
  }
  // STYLE BLOCK DRAG AND DROP
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ss-column-drag-handle {
    top: 5px;
    left: 3px;
  }

  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    // margin-left: 25px;
    width: 100%;
    .body-drag {
      display: -webkit-box;
      justify-content: space-between;
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 15px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 139px;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .card-column-header {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    position: relative;
    overflow: visible;
    margin-top: 5px;
    border: 1px solid #7f7d8457;
    padding: 4px;
    background-color: rgb(214 204 242 / 34%);
    width: 100%;
    border-radius: 4px;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 7px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 12px;
    width: 77px;
  }
}
</style>
<style lang="scss">
.type-project-update-setting {
  .input-title-sous-type {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
  .card-scene {
    max-height: calc(100vh - 255px) !important;
    height: calc(100vh - 255px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .scroll {
    max-height: calc(100vh - 300px) !important;
    height: calc(100vh - 300px) !important;
  }
}
</style>
